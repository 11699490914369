@font-face {
	font-family: "lato-regular";
	font-weight: 400;
	src: url("../fonts/Lato/Lato-Regular.ttf");
}
@font-face {
	font-family: "lato-black";
	font-weight: 900;
	src: url("../fonts/Lato/Lato-Black.ttf");
}

body {
	font-size: 16;
	color: #271e1e;
	font-family: "lato-regular", sans-serif;
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

.container_app {
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #D81717;	
}

.logo {
	width: 40%;
}

.logo img {
	max-width: 100%;
	display: block;
}

#inputEan {
	position: absolute;
	border: none;
	outline: none;
	color: transparent;
	position: absolute;
	background: transparent;
}

.iframe-container {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.fullwidth {
	width: 100%;
	height: 100%;
}

.loading,
.product,
.not_found {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
}

.not_found {
	z-index: 3;
	flex-direction: column;
}

.not_found p b {
	color: #d81717;
	font-weight: bold;
	font-size: 9vw;
	margin-bottom: 50px;
}

.not_found p {
	color: #271e1e;
	font-size: 6vw;
}

.product {
	z-index: 1;
}

.product_image,
.product_infos {
	width: 40%;
	padding: 20px;
	/* background: turquoise; */
}

.product_image img {
	max-width: 100%;
}

.product_name {
	font-size: 4.5vw;
}

.not_image .product_infos {
	width: 70%;
	text-align: center;
}

.product_prices {
	display: flex;
	flex-direction: column;
	margin-top: 30px;
}

.price_normal {
	font-size: 5vw;
	color: #a9a9a9;
	padding-left: 10px;
}

.price_normal b {
	text-decoration: line-through;
}

.price_offer {
	font-size: 8.5vw;
	color: #d81717;
	line-height: 1;
	font-weight: bold;
}

.form {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	max-width: 450px;
	width: 100%;
	background: #fff;
	padding: 16px;
	border-radius: 8px;
}

.groupForm {
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-bottom: 24px;
	position: relative;
}

.groupForm label {
	color: #2f2a2a;
	font-size: 18px;
	font-weight: 400;
	margin-bottom: 10px;
}

.groupForm input {
	height: 40px;
	border-radius: 8px;
	outline: none;
	border: 1px solid #2f2a2a52;
	padding-left: 10px;
	font-size: 18px;
	max-width: 100%;
	box-shadow: 1px 1px 3px 1px #dbdbdb;
}

.groupForm img {
	width: 30px;
	height: 30px;
	position: absolute;
	top: 53%;
	left: 87%;
}

.btn_submit {
	width: 100%;
}

.btn_submit button {
	width: 100%;
	height: 40px;
	border-radius: 8px;
	border: none;
	outline: none;
	font-size: 20px;
	color: #fff;
	background: #b01010;
	margin-bottom: 24px;
}

.btn_submit button:hover {
	opacity: 0.8;
}

.imgLogin {
	width: 350px;
	margin-bottom: 24px;
}

.imgLogin img {
	max-width: 100%;
	display: block;
}

.error {
	background: #ff9800;
	color: #fff;
	max-width: 100%;
	width: 100%;
	text-align: center;
	border-radius: 8px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 24px;
	margin-top: -10px;
	font-size: 18px;
}

.software-version {
	color: #b01010;
	font-size: 16px;
	position: absolute;
	bottom: 1rem;
	right: 1rem;
	font-weight: bold;
}
