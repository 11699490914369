.product-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 20px 0px;
  gap: 20px;
}

.product-found-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 720px;
  height: 220px;
  margin: 20px 0px;
  padding: 20px;
  border-radius: 20px;
  background-color: #fff;
}

.product-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 200px;
}

.product-image {
  width: 150px;
  height: 150px;
}

.product-image-not-found {
  font-size: 1.8rem;
  font-weight: 600;
  color: #C7C7C7;
}

.product-infos {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 200px;
  width: 450px;
  padding: 0.5rem;
  gap: 15px;
}

.product-name {
  font-size: 1.8rem;
  font-weight: 900;
  font-family: 'lato-black';
  color: #332C2C;
}

.discount-box {
  display: flex;
  align-items: center;
  gap: 10px;
}

.discount-value {
  font-size: 1.2rem;
  font-weight: 400;
  color: #6F6F6F;
  text-decoration: line-through;
}

.discount-porcent-box {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #16A33E;
  border-radius: 5px;
  padding: 2px 8px;
  width: 80px;
  gap: 5px;
}

.discount-porcent-box img {
  width: 10px;
  height: 10px;
}

.discount-porcent {
  font-size: 1rem;
  font-weight: 900;
  font-family: 'lato-black';
  color: #fff;
}

.prices-and-clube {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.product-prices {
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: start;
  align-items: start;
}

.price-offer {
  font-family: 'lato-black';
  font-size: 2.8rem;
  font-weight: 900;
  color: #C12527;
}

.club-box {
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  width: 200px;
  height: 70px;
  background-color: #FCDF67;
  border-radius: 5px;
  padding: 8px;
  border: 1px solid #F5A745;
}

.club-discount {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.club-label {
  font-size: 0.8rem;
  font-family: 'lato-black';
  font-weight: 900;
  color: #E84142;
}

.club-discount-box {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #E84142;
  border-radius: 5px;
  padding: 0px 5px;
}

.club-discount-box img {
  width: 10px;
  height: 10px;
}

.club-discount-text {
  font-size: 0.8rem;
  font-weight: 900;
  color: #fff;
}

.club-price {
  font-size: 1.8rem;
  font-weight: 900;
  font-family: 'lato-black';
  color: #E84142;
}

.club {
  width: 715px;
}