.loading {
	flex-direction: column;
  gap: 5.5rem;
}

.loading-image {
  width: 200px;
  height: 200px;
  animation: pulse 850ms infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.loading-text {
  font-size: 3rem;
  font-weight: bold;
  color: white;
}