.not-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.not-found-container {
  padding: 20px;
}

.product-message {
  width: 720px;
  height: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 20px;
  font-size: 3rem;
  font-weight: 600;
  color: #C7C7C7;
}

.club {
  width: 715px;
}
